.productCard {
  max-width: 120rem;
  display: flex;
  align-items: center;

  margin: 8rem auto 10rem;

  .hero {
    display: block;
    margin: 0rem 0rem 0rem 10rem;
    padding-right: 0rem;

    img {
      display: block;
      margin: 2rem 1rem 0 0;
      width: 40rem;
    }
  }

  .productDetails {
    ul,
    ul li {
      // display: flex;
      flex-direction: column;
      align-self: center;
      padding: 0;
      margin: 0rem auto 0;
    }

    ul li {
      width: 70%;
      list-style-type: none;
      margin: 0 auto 1rem;
      line-height: 3rem;

      h1 {
        margin: 0;
      }
    }
  }

  .addToCart {
    width: 20rem;
    margin: 2rem 0 2rem;
  }
}

@media only screen and (max-width: 1300px) {
  .productCard {
    .hero {
      padding-left: 4rem;
      margin: 0rem 0rem 0rem 4rem;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .productCard {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: -2rem;

    .productDetails {
      width: 51%;
      margin: 0 auto 0;
      ul,
      ul li {
        padding: 0;
        margin: 3rem 0;
      }

      ul li {
        width: 100%;

        list-style-type: none;
        margin: 0 0;
        line-height: 3rem;

        h1 {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .productCard {
    .hero {
      padding-left: 2rem;
      img {
        width: 45rem;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .productCard {
    .productDetails {
      width: 70%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .productCard {
    .hero {
      padding-left: 0rem;
      img {
        width: 30rem;
      }
    }
    .productDetails {
      width: 70%;
    }
  }
}
