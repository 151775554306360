.header {
  height: 7rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .wrap {
    display: block;
    position: relative;
    height: 100%;
    max-width: 1700px;
    margin: 0 auto;

    .logo {
      width: 25rem;
      position: relative;
      top: 50%;
      left: 1;
      margin-left: 30px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);

      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    nav {
      display: block;
      width: 100%;
      height: 100%;
      margin: -5.9rem 0;
      padding: 0;

      ul,
      ul li {
        margin: 0;
        padding: 0;
        height: 100%;
      }

      ul {
        text-align: center;

        li {
          display: inline-block;
          margin: 0 1.5rem;
          list-style-type: none;

          a {
            font-size: 2rem;
            line-height: 6.5rem;
            vertical-align: middle;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }
    .callToActions {
      position: absolute;
      right: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        list-style-type: none;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
      a {
        font-size: 1.6rem;
        line-height: 1;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
      }
      span {
        font-size: 1.6rem;
        line-height: 1;
        color: black;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .ipadMenu {
      display: none;
    }
  }
}

@media only screen and (max-width: 980px) {
  .regular {
    display: none;
  }

  .header {
    .wrap {
      .logo {
        width: 20.8rem;
      }
      nav {
        margin-top: -4.9rem;
        ul {
          li {
            a {
              font-size: 1.8rem;
            }
          }
        }
      }
      .ipadMenu {
        display: inline-block;
        padding: 0 1rem;
      }
    }
  }
}

@media only screen and (max-width: 783px) {
  .header {
    .wrap {
      .logo {
        width: 23.8rem;
      }
      nav {
        display: none;
      }
    }
  }
}
