.checkout {
  margin: 2rem auto;

  h1 {
    display: block;
    width: 100%;
  }

  h1,
  p {
    text-align: center;
  }

  .checkoutHeader {
    border-bottom: 1px solid black;
  }

  .cart {
    max-width: 100rem;
    margin: 0 auto;

    table {
      width: 100%;
    }
  }

  .checkoutHeader,
  .cart {
    width: 100%;
    text-align: left;
    margin-top: 2rem;

    th,
    td {
      width: 22%;
    }
  }

  .cartItem {
    td img {
      display: block;
      width: 80%;
      margin-top: 1rem;
      margin-left: -1rem;
    }
  }

  .empty {
    min-height: 50rem;
    margin-top: 5rem;
  }

  .cartBtn {
    cursor: pointer;
    padding: 0 1rem;
  }
}
