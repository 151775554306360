.about {
  //   height: 100%;
  max-width: 1450px;

  .wrap {
    display: inline-block;
    width: 100%;
    // height: 100%;

    .aboutHeader {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 5rem auto 3rem;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 3rem;
        padding-bottom: 0.6rem;
      }

      .aboutLogo {
        width: 2.8%;
      }
    }

    .content {
      img {
        float: left;
        width: 35%;
        margin-right: 18rem;
        margin-left: 9rem;
        margin-bottom: 10rem;
        margin-top: 3rem;
      }
      ul,
      li {
        margin: 4rem 2rem;
        padding: 0.01rem 2rem;
        text-align: center;
        line-height: 4rem;
        font-size: 2rem;
      }

      li {
        // width: 90%;
        margin-top: 0rem;
        list-style-type: none;
        margin-left: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .about {
    .wrap {
      .content {
        img {
          margin-left: 10rem;
          margin-right: 15rem;
          margin-bottom: 15rem;
        }
        ul,
        li {
          margin: 1rem 2rem;
          padding-bottom: 3rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1310px) {
  .about {
    .wrap {
      .content {
        img {
          margin-left: 10rem;
          margin-right: 10rem;
          margin-bottom: 20rem;
        }
        ul,
        li {
          margin: 1rem 2rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1210px) {
  .about {
    .wrap {
      .aboutHeader {
        margin: 5rem auto 4rem;
      }
      .content {
        display: flex;
        flex-direction: column;

        img {
          align-self: center;
          width: 40%;
          margin-bottom: 2rem;
          margin-top: 1rem;
        }
        ul,
        li {
          margin: 2rem 2rem -2rem;
          padding-bottom: 4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .about {
    .wrap {
      .aboutHeader {
        .aboutLogo {
          width: 6%;
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .about {
    .wrap {
      .aboutHeader {
        h1 {
          font-size: 2.8rem;
        }

        .aboutLogo {
          width: 6%;
        }
      }
      .content {
        display: flex;
        flex-direction: column;

        img {
          align-self: center;
          width: 50%;
          margin-bottom: 2rem;
          margin-top: 0rem;
        }
        ul,
        li {
          margin: 2rem 2rem -2rem;
          padding-bottom: 4rem;
        }
      }
    }
  }
}
