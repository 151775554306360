.btn {
  display: block;
  width: 100%;
  background: black;
  color: white;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
  margin: auto;
  padding: 1rem 0;
  outline: none;
  cursor: pointer;
}
