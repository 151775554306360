.products {
  display: block;
  width: 100%;
  padding: 0;
  margin: 4rem 0;

  h1 {
    text-align: center;
    padding-bottom: 2rem;
  }
}

.productResults {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem -10px 0;
  align-items: center;
}

.product {
  width: 30%;
  margin: 0 auto 2rem;
  padding: 0 10px;

  .thumb {
    display: block;
    width: 75%;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      // height: 500px;
      margin: 0;
    }
  }

  .details {
    display: block;

    width: 100%;
    margin: 0 auto;
    padding: 1rem;

    ul,
    ul li {
      padding: 0;
      margin: 1rem 0;
    }

    ul {
      li {
        display: block;
        width: 100%;
        list-style-type: none;
        margin: 0 0 0.6rem;
        text-align: center;
        align-items: center;

        a {
          color: black;
        }

        .name {
          font-size: 2.2rem;
          line-height: 1.3;
          font-weight: 400;
        }

        .price {
          font-size: 1.6rem;
          line-height: 1;
        }

        .addToCart {
          width: 70%;
          margin: 4rem 0;
          position: relative;
          top: 50%;
          left: 50%;
          // -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .product {
    width: 40%;
  }
}

@media only screen and (max-width: 690px) {
  .product {
    width: 70%;
  }
}
