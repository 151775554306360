.admin {
  padding: 0 10px;

  h6 {
    cursor: pointer;
    width: 4.5%;
    border-bottom: 0.2px solid #d3d3d3;
  }

  .callToActions {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0 auto;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul {
      li {
        display: inline-block;

        button {
          padding: 0.5rem;
        }
      }
    }
  }
}

.manageProducts {
  h1 {
    margin-top: 2.5rem;
    border-bottom: 1px solid #d3d3d3;
  }
  table.results {
    tr {
      &:nth-child(even) {
        background-color: #d3d3d3;
      }
      .thumb {
        width: 15rem;
        margin: 0 auto;
      }
    }
  }
}
