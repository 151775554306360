.mobileMenu {
  margin-top: 0.7rem;
  margin-right: -1rem;

  .searchIcon {
    display: none;
  }
}

@media only screen and (max-width: 783px) {
  .mobileMenu {
    margin-right: -2rem;

    .searchIcon {
      display: inline-block;
      margin-right: 2rem;
      // margin-top: 5rem;
    }
  }
}
