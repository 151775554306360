.paymentDetails {
  display: block;
  width: 60%;
  max-width: 100rem;
  padding: 0;
  margin: 4rem auto;

  .group {
    margin: 0 0 2rem;
  }
}
