.directory {
  height: 100%;
  .wrap {
    display: inline-block;
    width: 100%;
    height: 100%;

    .item {
      position: relative;
      width: 49%;
      height: 50rem;
      margin: 70px auto;
      padding-right: 10px;
      float: left;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 7px;
    }

    a {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-10%, -50%);
      -moz-transforrm: translate(-10%, -50%);
      transform: translate(-50%, 800%);
      margin: 0 auto;
      z-index: 2;
      font-size: 1.8rem;
      line-height: 1;
      font-weight: 400;
      text-transform: uppercase;
      background: black;
      padding: 0.8rem 2rem;
      border: 1px solid black;
      color: white;
    }
    a:hover {
      background: white;
      color: black;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .directory {
    .wrap {
      .item {
        margin-top: 5rem;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .directory {
    .wrap {
      .item {
        margin-top: 4rem;
      }

      a {
        top: 45%;
      }
    }
  }
}

@media only screen and (max-width: 1005px) {
  .directory {
    .wrap {
      a {
        top: 48%;
        font-size: 1.5rem;
        transform: translate(-50%, 850%);
      }
    }
  }
}

@media only screen and (max-width: 783px) {
  .directory {
    .wrap {
      display: flex;
      flex-direction: column;

      .item {
        width: 100%;
      }
    }
  }
}
