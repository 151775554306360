.formWrap {
  .login {
    margin-top: 2.5rem;
  }

  p {
    line-height: 2.3rem;
    text-align: center;
    font-size: 1.5rem;
    font-family: "Courier New", Courier, monospace;
  }
}

.socialSignin {
  margin: 1rem auto 0;
}

.links {
  display: block;
  width: 100%;
  margin: 1.5rem auto 0;

  a {
    color: black;
    font-size: 1.3rem;
  }
}
