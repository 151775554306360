.footer {
  background-color: #343434;
  .wrap {
    width: 100%;
    margin-top: 120px;
    padding: 10px 20px 50px 100px;
    display: flex;
    justify-content: space-between;
    color: white;
  }

  .left {
    display: flex;
    .contact {
      margin-right: 15rem;
    }
  }

  h5 {
    font-size: 1.2rem;
    line-height: 1.5;
    color: white;
  }
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    font-size: 1.1rem;
    line-height: 2;
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: white;
  }
  .hours {
    margin-right: 12rem;
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    .wrap {
      padding-left: 5rem;
    }
    .left {
      .contact {
        margin-right: 9rem;
      }
      .about {
        margin-left: 0rem;
      }
    }
    .hours {
      margin-right: 6rem;
    }
  }
}

@media only screen and (max-width: 635px) {
  .wrap {
    .left {
      .about {
        padding-right: 6rem;
      }
    }

    .hours {
      margin-right: 2rem;
    }
  }
}
